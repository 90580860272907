const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    //component: "UnderConstruction",
    component: "Home",
  },
  // routes for each tenant
  {
    path: ["*/reset-password"],
    exact: true,
    component: "ResetPassword",
  },
  {
    path: ["*/confirm-email"],
    exact: true,
    component: "ConfirmEmail",
  },
  {
    path: ["*/accept-invite"],
    exact: true,
    component: "AcceptInvite",
  },
  {
    path: ["*/app"],
    exact: true,
    component: "DownloadApp",
  },
  {
    path: ["*/privacy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["*/payment/success"],
    exact: true,
    component: "PaymentResult",
  },
  {
    path: ["*/payment/error"],
    exact: true,
    component: "PaymentResult",
  },
  // fallback
  {
    path: ["*"],
    exact: true,
    component: "DownloadApp",
  },
];

export default routes;
